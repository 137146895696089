import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage3Light from "./pages/Homepage3Light";
import Bloglist from "./pages/Bloglist";
import BlogDetails from "./pages/BlogDetails";
import Worklist from "./pages/Worklist";
import WorkDetails from "./pages/WorkDetails";
import "./App.scss";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Homepage3Light />
        </Route>
        <Route path="/blogs" component={Bloglist} exact />
        <Route path="/blogs/:id/:title" component={BlogDetails} />
        <Route path="/works" component={Worklist} exact />
        <Route path="/works/:id/:title" component={WorkDetails} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
