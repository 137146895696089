import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Diploma in Information Technology",
    years: "2022 - Present",
    content:
      "Singapore Polytechnic",
  },
  {
    id: 2,
    title: "Higher Nitec in IT Systems & Networks",
    years: "2020 - 2022",
    content:
      "Institute of Technical Education",
  },
  {
    id: 3,
    title: "GCE O/N Levels",
    years: "2015 - 2020",
    content:
      "Regent Secondary School",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Web Engineer",
    years: "Mar 2024 - Present",
    content:
      "eFusion Technology",
  },
  {
    id: 2,
    title: "Founder & CEO",
    years: "Mar 2024 - Present",
    content: (
      <span>
        <a href="https://creativemedia.sg" target="_blank" rel="noopener noreferrer">
          CreativeMedia Singapore
        </a>
      </span>
    ),
  },
  {
    id: 3,
    title: "Photography Assistant",
    years: "Dec 2020 - Mar 2024",
    content:
      "Photoplay Singapore",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
